import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/game/card/hottest.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/betting-guide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/featured-picks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/hot-game-picks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/how-moneyline-works.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/latest-injuries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/scoreboard-skeleton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/scoreboard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/top-pickers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/top-sportsbooks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/news/featured-news.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/news/news-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/news/recent-news.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/primitives/button.tsx");
